import React from 'react';
import { Link, graphql, useStaticQuery } from "gatsby";

import SiteHeaderStyles from "./Header.module.scss";

import { FaRegComments } from "react-icons/fa";

import logoImage from "../images/kac.png";


const Header = () => {

    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    tagline
                    email
                }
            }
        }
    `);

    return (
        <header className={SiteHeaderStyles.siteHeader}>

            <div className={SiteHeaderStyles.siteHeader__callout}>
                <div className="inner-container">
                    <h2><FaRegComments /> Need Help?</h2>
                    <p>
                        <a href={`mailto:${data.site.siteMetadata.email}?Subject=An inquiry from KickAssCoins.com`} title="Email Kick Ass Coins" rel="noopener noreferrer">Contact us via email</a>, or <a href="https://twitter.com/intent/tweet?screen_name=kickasscoins" title="Tweet @KickAssCoins" rel="noopener noreferrer">Tweet us @KickAssCoins</a> and we'll be happy to assist you.
                    </p>
                </div>
            </div>

            <div className={`${SiteHeaderStyles.siteHeader__content} inner-container`}>
                <h1 className="visually-hidden">{data.site.siteMetadata.title}</h1>
                <figure>
                    <Link to="/">
                        <img src={logoImage} title={data.site.siteMetadata.title} alt={data.site.siteMetadata.title} />
                        <figcaption>{data.site.siteMetadata.tagline}</figcaption>
                    </Link>
                </figure>
            </div>
            
        </header>
    );
};

export default Header;