import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import SocialStyles from "./Social.module.scss";

import { FaTwitter, FaEnvelope, FaShoppingCart, FaInstagram } from "react-icons/fa";

const Social = () => {

    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    ebay
                    instagram
                    email
                    twitter
                }
            }
        }
    `);

    return (
        <div className={SocialStyles.social}>
            <a href={data.site.siteMetadata.twitter} title="Kick Ass Coins on Twitter" rel="noopener noreferrer"><FaTwitter /></a>
            <a href={data.site.siteMetadata.ebay} title="Kick Ass Coins eBay Store" rel="noopener noreferrer"><FaShoppingCart /></a>
            <a href={data.site.siteMetadata.instagram} title="Kick Ass Coins on Instagram" rel="noopener noreferrer"><FaInstagram /></a>
            <a href={`mailto:${data.site.siteMetadata.email}?Subject=An inquiry from KickAssCoins.com`} title="Email Kick Ass Coins" rel="noopener noreferrer"><FaEnvelope /></a>
        </div>
    );
};

export default Social;