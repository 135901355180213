import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import { Helmet } from "react-helmet";

const Head = ({ title }) => {
const data = useStaticQuery(graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
    }
`);

return <Helmet 
    script={[{"src": "https://www.auctionnudge.com/feed/item/js/theme/unstyled/img_size/420/lang/english/SellerID/kickasscoins/siteid/0/MaxEntries/3", "type": "text/javascript", "defer": "defer"}]} 
    defaultTitle={`${title} | ${data.site.siteMetadata.title}`}>
        <meta name="description" content={data.site.siteMetadata.description} />
    </Helmet>;
};

export default Head;