import React from 'react';
import { Link } from "gatsby";

import SiteNavigationStyles from "./Navigation.module.scss";

import { FaCashRegister, FaHandHoldingUsd, FaCoins } from "react-icons/fa";

const Navigation = () => {
    return (
        <nav className={SiteNavigationStyles.siteNavigation}>
            <div className="inner-container">
                <ul>
                    <li>
                        <Link to="/#showcase" title="eBay Showcase">
                            <h2><FaCashRegister /> eBay Showcase</h2>
                            <p>Hundreds of listings; an ebay Top-Rated Seller with 100% Positive Feedback!</p>
                        </Link>
                    </li>
                    <li>
                        <Link to="/#consignment" title="Purchasing and Consignment">
                            <h2><FaHandHoldingUsd /> Purchasing &amp; Consignment</h2>
                            <p>Inherit a collection? Want to get the most you can for it? Put it in a Kick Ass store!</p>
                        </Link>
                    </li>
                    <li>
                        <Link to="/#collect" title="Collect Like a Pro">
                            <h2><FaCoins /> Collect Like A Pro</h2>
                            <p>Expand your knowledge like a professional numismatist with our FREE eBook!</p>
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Navigation;