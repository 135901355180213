import React from "react";

import Header from "./Header";
import Navigation from "./Navigation";
import Footer from "./Footer";

import "../styles/kac-main.scss";

const Layout = props => {
    return (
        <div>
            <Header />
            <Navigation />
            <main>
                <article>
                    {props.children}
                </article>
            </main>
            <Footer />
        </div>
    );
};

export default Layout;
