import React from 'react';
import { Link, graphql, useStaticQuery } from "gatsby";

import Social from "./Social";
import SiteFooterStyles from "./Footer.module.scss";

import { FaRegQuestionCircle } from "react-icons/fa";

import btcImg from "../images/btc.png";
import dgbImg from "../images/dgb.png";
import ltcImg from "../images/ltc.png";
import xlmImg from "../images/xlm.png";
import xvgImg from "../images/xvg.png";


const Footer = () => {

    const currentYear = () => {
        const newDate = new Date();
        return newDate.getFullYear();
    };

    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    anaNumber
                    email
                    twitter
                }
            }
        }
    `);

    return (
        <footer className={SiteFooterStyles.siteFooter}>
            <div className={`${SiteFooterStyles.siteFooter__container} inner-container`}>

                <div className={SiteFooterStyles.siteFooter__text}>
                    <div className={SiteFooterStyles.siteFooter__textGroup}>
                        <h3><FaRegQuestionCircle />Questions? Comments?</h3>
                        <p>Connect with us via <a href={`mailto:${data.site.siteMetadata.email}?Subject=An inquiry from KickAssCoins.com`} title="Email Kick Ass Coins" rel="noopener noreferrer">email at kickasscoins@gmail.com</a>, or <a href="https://twitter.com/intent/tweet?screen_name=kickasscoins" title="Tweet @KickAssCoins" rel="noopener noreferrer">Tweet us @KickAssCoins</a>.</p>
                    </div>
                    <div className={SiteFooterStyles.siteFooter__textGroup}>
                        <p>&copy; {currentYear()} <Link to="/">{data.site.siteMetadata.title}</Link>, all rights reserved.<br />Proud member of the American Numismatist Association, #{data.site.siteMetadata.anaNumber}.</p>
                    </div>
                    
                    <Social />

                    <div className={SiteFooterStyles.siteFooter__textGroup}>
                        <address>
                            PO Box 11<br />
                            Pompton Plains, NJ 07444
                        </address>
                    </div>
                </div>

                <div className={SiteFooterStyles.siteFooter__crypto}>
                    <h3>Now accepting Cryptocurrencies!</h3>
                    <p><a href={`mailto:${data.site.siteMetadata.email}?Subject=An inquiry from KickAssCoins.com Re: Cryptocurrencies`} title="Email Kick Ass Coins" rel="noopener noreferrer">Reach out to learn how to purchase collectible coins with digital ones!</a></p>
                    <div className={SiteFooterStyles.siteFooter__cryptoGroup}>
                        <figure>
                            <img src={btcImg} alt="Bitcoin" />
                            <figcaption>Bitcoin</figcaption>
                        </figure>
                        <figure>
                            <img src={dgbImg} alt="DigiByte" />
                            <figcaption>DigiByte</figcaption>
                        </figure>
                        <figure>
                            <img src={ltcImg} alt="Litecoin" />
                            <figcaption>Litecoin</figcaption>
                        </figure>
                        <figure>
                            <img src={xlmImg} alt="Stellar Lumens" />
                            <figcaption>Stellar</figcaption>
                        </figure>
                        <figure>
                            <img src={xvgImg} alt="Verge" />
                            <figcaption>Verge</figcaption>
                        </figure>
                    </div>
                </div>

            </div>
        </footer>
    );
};

export default Footer;